import React, { useEffect, useCallback } from 'react';
import { useSystemContext } from './contexts/SystemContext';

import { socket } from './services/socket';
import Storage from './utils/Storage';
import i18n from './i18n';

import _ from 'lodash';

import Widget from './components/Widget/Widget';

import './App.less';

const App = (props) => {
    const {
        licenseKey, setLicenseKey,
        setLanguage,
        client, updateClient,
        visitor, updateVisitor,
        setChatId,
        setPreviewChatbotId,
        isSocketReadyToConnect, setIsSocketReadyToConnect,
        isSocketConnected, setIsSocketConnected
    } = useSystemContext();

    const widgetName = process && process.env && process.env.REACT_APP_WIDGET_NAME;
    // hmtTODO https://stackoverflow.com/questions/70357360/process-is-not-defined-on-hot-reload/70374227

    useEffect(() => {
        const getDeviceType = () => {
            let userAgent = ((window.navigator && (window.navigator.userAgent || window.navigator.vendor)) || window.opera || null);
            if(!userAgent) return 'Unknown';
        
            userAgent = userAgent.toLowerCase();
        
            if(/ipad/i.test(userAgent)) return 'Tablet (iOS)';
            if(/android(?!.*mobi)/i.test(userAgent)) return 'Tablet (Android)';
            if(/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) return 'Tablet (Other)';
        
            if(/ip(hone|od|ad)/.test(userAgent)) return 'Mobile (iOS)';
            if(/android/.test(userAgent)) return 'Mobile (Android)';
            if(/mobile|ip(hone|od|ad)|android|blackberry|iemobile|kindle|silk-accelerated|(hpw|web)os|opera m(obi|ini)/.test(userAgent)) return 'Mobile (Other)';
        
            return 'Desktop';
        }

        const onPostMessageListener = (message) => {
            let messageData = (message && message.data) || null;
            if(!messageData) return;

            if(typeof(messageData) !== 'object') {
                try {
                    messageData = JSON.parse(messageData);
                } catch(err) {
                    console.debug('App.js/onPostMessageListener', message);
                    return;
                }
            }
            
            if(!messageData) return;
            if(!messageData.from || messageData.from !== widgetName) return;
            if(!messageData.action) return;

            console.log('--- App.js::onPostMessageListener()', messageData);

            switch(messageData.action) {
                case 'init': {
                    if(!messageData.payload) return;
                    if(!messageData.payload.licenseKey) return;

                    setLicenseKey(messageData.payload.licenseKey);
                    setLanguage(messageData.payload.language || 'tr');

                    if(!messageData.payload.visitor) messageData.payload.visitor = {};
                    messageData.payload.visitor.deviceType = getDeviceType();

                    updateVisitor(visitor => {
                        let nVisitor = { ...messageData.payload.visitor };
                        if(visitor) nVisitor = { ...visitor, ...nVisitor };
                        if(!_.isEqual(visitor, nVisitor)) return nVisitor;
                    });

                    setPreviewChatbotId(messageData.payload.previewChatbotId || null);

                    setIsSocketReadyToConnect(true);

                    break;
                }

                case 'setVisitor': {
                    if(!messageData.payload) return;
                    if(!messageData.payload.visitor) return;

                    updateVisitor(visitor => {
                        let nVisitor = { ...messageData.payload.visitor };
                        if(visitor) nVisitor = { ...visitor, ...nVisitor };
                        if(!_.isEqual(visitor, nVisitor)) return nVisitor;
                    });

                    break;
                }

                default: {
                    // nothing-to-do
                }
            }
        };

        window.addEventListener('message', onPostMessageListener, false);

        return function cleanup() {
            window.removeEventListener('message', onPostMessageListener);
        }
    }, [widgetName, setIsSocketReadyToConnect, setLanguage, setLicenseKey, setPreviewChatbotId, updateVisitor]);

    useEffect(() => {
        window && window.top && window.top.postMessage(JSON.stringify({
            from: widgetName,
            action: 'onload'
        }), '*');
    }, [widgetName]);

    const onSocketConnect = useCallback(() => {
        socket.emit('visitor.connect', { visitor: { ...visitor, sessionId: Storage.get(widgetName + '-client-' + licenseKey + '-chat-sessionId') } }, (ack) => {
            if(ack && ack.result) {
                if(ack.client)  {
                    updateClient(client => ack.client);
                    setIsSocketConnected(true);

                    // Send 'ready' message back to the window
                    if(ack.client.parameters && ack.client.parameters.widget && ack.client.parameters.widget.language) i18n.changeLanguage(ack.client.parameters.widget.language);

                    window && window.top && window.top.postMessage(JSON.stringify({
                        from: widgetName,
                        action: 'ready',
                        payload: {
                            client: {
                                parameters: {
                                    widget: (ack.client.parameters && ack.client.parameters.widget ? ack.client.parameters.widget : null)
                                }
                            }
                        }
                    }), '*');
                }

                if(ack.sessionId && ack.sessionId !== Storage.get(widgetName + '-client-' + licenseKey + '-chat-sessionId')) Storage.set(widgetName + '-client-' + licenseKey + '-chat-sessionId', ack.sessionId);

                if(ack.chatId) setChatId(ack.chatId);
            }
        });
    }, [visitor, licenseKey, setChatId, setIsSocketConnected, updateClient, widgetName]);

    useEffect(() => {
        if(isSocketReadyToConnect) {
            socket.off('connect').on('connect', () => { // Fired upon connection (including a successful reconnection)
                onSocketConnect();
            });

            socket.off('disconnect').on('disconnect', () => {
                setIsSocketConnected(false);
            });

            if(licenseKey) socket.auth = { licenseKey: licenseKey };

			if(!socket.connected) socket.connect();

            return function cleanup() {
                socket.off('connect');
                socket.off('disconnect');
            }
        }
    }, [isSocketReadyToConnect, setIsSocketConnected, onSocketConnect, licenseKey ]);

    useEffect(() => {
        if(isSocketReadyToConnect) {
            socket.off('visitor.kill').on('visitor.kill', (pData) => {
                window && window.top && window.top.postMessage(JSON.stringify({
                    from: widgetName,
                    action: 'kill',
                }), '*');
            });

            socket.off('visitor.banned').on('visitor.banned', (pData) => {
                window && window.top && window.top.postMessage(JSON.stringify({
                    from: widgetName,
                    action: 'kill',
                }), '*');
            });

            return function cleanup() {
                socket.off('visitor.kill');
                socket.off('visitor.banned');
            }
        }
    }, [isSocketReadyToConnect, widgetName]);
    
    return (
        (licenseKey && client && visitor && isSocketConnected) ? (
            <Widget />
        ) : (
            <></>
        )
    );
}

export default App;
