class Storage {
    static isLocalStorageAvailable = () => {
        try {
            if(typeof window.localStorage !== 'undefined') {
                window.localStorage.setItem('_', '_');
                if(window.localStorage.getItem('_') === '_') {
                    window.localStorage.removeItem('_');
                    return true;
                }
            }
        } catch(e) {
        }

        return false;
    }

    static isSessionStorageAvailable = () => {
        try {
            if(typeof window.sessionStorage !== 'undefined') {
                window.sessionStorage.setItem('_', '_');
                if(window.sessionStorage.getItem('_') === '_') {
                    window.sessionStorage.removeItem('_');
                    return true;
                }
            }
        } catch(e) {
        }

        return false;
    }

    static get = (key, defaultValue = null) => {
        if(Storage.isLocalStorageAvailable()) {
            return window.localStorage.getItem(key, defaultValue);
        } else if(Storage.isSessionStorageAvailable()) {
            return window.sessionStorage.getItem(key, defaultValue);
        }

        return defaultValue;
    }

    static set = (key, value) => {
        if(Storage.isLocalStorageAvailable()) {
            window.localStorage.setItem(key, value);
        } else if(Storage.isSessionStorageAvailable()) {
            window.sessionStorage.setItem(key, value);
        }

        if(Storage.get(key) === value) return true;

        return false;
    }
};

export default Storage;