import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useImmer } from "use-immer";

export const SystemContext = createContext();

export const SystemContextProvider = ({ children }) => {
    const [systemVariables, setSystemVariables] = useState(null);
    const [licenseKey, setLicenseKey] = useState(null);
    const [language, setLanguage] = useState('tr');
    const [client, updateClient] = useImmer(null);
    const [visitor, updateVisitor] = useImmer(null);
    const [chatId, setChatId] = useState(null);
    const [previewChatbotId, setPreviewChatbotId] = useState(null);

    const [isSocketReadyToConnect, setIsSocketReadyToConnect] = useState(false);
    const [isSocketConnected, setIsSocketConnected] = useState(false);

    const fetchSystemVariables = useCallback(async () => {
        let fetchSystemVariablesResponse = await fetch(process.env.REACT_APP_CHATLINE_API_ADDRESS + 'client/system/variables');
        fetchSystemVariablesResponse = await fetchSystemVariablesResponse.json();
        setSystemVariables(fetchSystemVariablesResponse);
    }, []);

    useEffect(() => {
        fetchSystemVariables();
	}, [fetchSystemVariables]);

    return (
        <SystemContext.Provider value={{
            systemVariables, setSystemVariables,
            licenseKey, setLicenseKey,
            language, setLanguage,
            client, updateClient,
            visitor, updateVisitor,
            chatId, setChatId,
            previewChatbotId, setPreviewChatbotId,
            isSocketReadyToConnect, setIsSocketReadyToConnect,
            isSocketConnected, setIsSocketConnected
        }}>
            { children }
        </SystemContext.Provider>
    );
}

export const useSystemContext = () => useContext(SystemContext);