import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useImmer } from "use-immer";
import { useSystemContext } from '../../contexts/SystemContext';
import { useTranslation } from 'react-i18next';

import { socket } from '../../services/socket';

import _ from 'lodash';
import moment from 'moment';
import axios from 'axios';
import { v4 as uuid } from 'uuid';
import nl2br from 'react-nl2br';
import Linkify from 'react-linkify';

import Picker, { SKIN_TONE_LIGHT } from 'emoji-picker-react';

import { Form, Input, Select, Button, Carousel, Avatar, Upload, Progress } from 'antd';
import { CloseOutlined, MessageOutlined, SendOutlined, ArrowLeftOutlined, ArrowRightOutlined, LoadingOutlined, DownloadOutlined, FileOutlined, LikeOutlined } from '@ant-design/icons';

import asset_audio_newMessage from '../../assets/audio/new-message.mp3';

import './Widget.less';

const Widget = (props) => {
    const {
        systemVariables,
        language,
        client,
        visitor, updateVisitor,
        chatId,
        previewChatbotId
    } = useSystemContext();

    const widgetName = process && process.env && process.env.REACT_APP_WIDGET_NAME;

    const { t } = useTranslation();

    const [step, setStep] = useState(1);
    const [step1_channel, step1_setChannel] = useState(null);
    const [step2_departments, step2_setDepartments] = useState(null);
    const [step3_queuePosition, step3_updateQueuePosition] = useImmer(0);
    const [step3_isEndChatConfirmationVisible, step3_setIsEndChatConfirmationVisible] = useState(false);
    const [step4_isEndChatConfirmationVisible, step4_setIsEndChatConfirmationVisible] = useState(false);
    const [step4_chatId, step4_setChatId] = useState(null);
    const [step4_chatbotNodeId, step4_setChatbotNodeId] = useState(null);
    const [, step4_setOperator] = useState(null);
    const [step4_messages, step4_updateMessages] = useImmer(null);
    const [step4_messageComposerForm] = Form.useForm();
    const [step4_messageComposerForm_emojiPickerVisible, step4_messageComposerForm_setEmojiPickerVisible] = useState(false);
    const step4_ref_bottomOfChatLog = useRef(null);
    const step4_ref_file_input = useRef(null);
    const step4_ref_audio_newMessage = useRef(null);
    const [step4_file_uploadProgress, step4_file_setUploadProgress] = useState(0);
    const [step5_warningMessage, step5_setWarningMessage] = useState(null);

    const toggleWidget = useCallback(() => {
        window && window.top && window.top.postMessage(JSON.stringify({
            from: widgetName,
            action: 'toggle'
        }), '*');
    }, [widgetName]);

    const expandWidget = useCallback(() => {
        window && window.top && window.top.postMessage(JSON.stringify({
            from: widgetName,
            action: 'expand'
        }), '*');
    }, [widgetName]);

    const killWidget = useCallback(() => {
        window && window.top && window.top.postMessage(JSON.stringify({
            from: widgetName,
            action: 'kill'
        }), '*');
    }, [widgetName]);

    useEffect(() => {
        socket.emit('visitor.client.departments.get', null, (ack) => {
            if(ack && ack.result && ack.departments) step2_setDepartments(ack.departments);
        });
    }, []);

    useEffect(() => {
        if(client) {
            if(step === 1 && !(client.parameters && client.parameters.widget && client.parameters.widget.preChat1_isVisible !== null && parseInt(client.parameters.widget.preChat1_isVisible) === 1)) {
                setStep(2);
            }
        }
    }, [client, step]);

    useEffect(() => {
        if(chatId) {
            step4_setChatId(chatId);
        }
    }, [chatId]);

    useEffect(() => {
        if(previewChatbotId && language) {
            let values = {
                name: 'test',
                username: 'test',
                email: 'test'
            };

            updateVisitor(visitor => {
                let nVisitor = {
                    name: values.name,
                    username: values.username,
                    email: values.email
                };
    
                if(visitor) nVisitor = { ...visitor, ...nVisitor };
                if(!_.isEqual(visitor, nVisitor)) return nVisitor;
            });
    
            socket.emit('visitor.chat.start', { chatbotId: previewChatbotId, language: language, ...values }, (ack) => {
                // nothing-to-do
            });   
        }
    }, [previewChatbotId, language, updateVisitor]);

    useEffect(() => {
        socket.off('visitor.chat.start').on('visitor.chat.start', (pChat) => {
            if(!pChat) return;

            step4_setChatId(pChat._id);

            if(pChat.chatbotId) {
                setStep(4);
            } else if(pChat.operatorId) {
                if(pChat.operator) step4_setOperator(pChat.operator);
                step3_updateQueuePosition(step3_queuePosition => 0);
                setStep(4);
            } else {
                step3_updateQueuePosition(step3_queuePosition => (pChat.queuePosition && parseInt(pChat.queuePosition) > 0 ? parseInt(pChat.queuePosition) : 0));
                setStep(3);
            }

            if(!pChat.message) return;

            socket.emit('visitor.chat.message', { message: pChat.message }, (ack) => {
                if(ack && ack.result) {
                    // nothing-to-do
                }
            });
        });

        socket.off('visitor.queuePosition.decrement').on('visitor.queuePosition.decrement', () => {
            step3_updateQueuePosition(step3_queuePosition => {
                return (step3_queuePosition > 1 ? (step3_queuePosition - 1) : 1);
            });
        });

        return function cleanup() {
            socket.off('visitor.chat.start');
            socket.off('visitor.queuePosition.decrement');
        }
    }, [step3_updateQueuePosition]);

    useEffect(() => {
        if(step4_chatId) {
            socket.off('chat.message').on('chat.message', (chatMessage) => {
                if(step4_chatId && ''+step4_chatId !== ''+chatMessage.chatId) return;

                if(chatMessage && chatMessage.messageBy && chatMessage.messageBy.userType !== 'visitor') {
                    step4_ref_audio_newMessage && step4_ref_audio_newMessage.current && step4_ref_audio_newMessage.current.play().catch(err => {});
                }

                if(chatMessage.parameters && chatMessage.parameters.chatbotId) {
                    chatMessage.parameters.chatbotNodeId && step4_setChatbotNodeId(chatMessage.parameters.chatbotNodeId);
                } else if(chatMessage.messageBy && chatMessage.messageBy.userType && chatMessage.messageBy.userType === 'operator') {
                    step4_setChatbotNodeId(null);
                }

                step4_updateMessages(step4_messages => {
                    if(!step4_messages) {
                        let xMessages = {};
                        xMessages[chatMessage._id] = chatMessage;
                        return xMessages;
                    }

                    let xPreviousMessage = (step4_messages && (Object.values(step4_messages))[0]) || null;
                    if(!xPreviousMessage || (xPreviousMessage && xPreviousMessage.chatId && ''+xPreviousMessage.chatId === ''+chatMessage.chatId)) {
                        step4_messages[chatMessage._id] = chatMessage;
                    }
                });
            });

            socket.off('chat.message.edit').on('chat.message.edit', (chatMessage) => {
                if(step4_chatId && ''+step4_chatId !== ''+chatMessage.chatId) return;

                step4_updateMessages(step4_messages => {
                    if(!step4_messages) return { [chatMessage._id]: chatMessage };

                    let xPreviousMessage = (step4_messages && (Object.values(step4_messages))[0]) || null;
                    if(!xPreviousMessage || (xPreviousMessage && xPreviousMessage.chatId && ''+xPreviousMessage.chatId === ''+chatMessage.chatId)) {
                        step4_messages[chatMessage._id] = chatMessage;
                    }
                });
            });

            socket.off('chat.message.delete').on('chat.message.delete', (chatMessage) => {
                if(step4_chatId && ''+step4_chatId !== ''+chatMessage.chatId) return;

                step4_updateMessages(step4_messages => {
                    if(!step4_messages) return { [chatMessage._id]: chatMessage };

                    let xPreviousMessage = (step4_messages && (Object.values(step4_messages))[0]) || null;
                    if(!xPreviousMessage || (xPreviousMessage && xPreviousMessage.chatId && ''+xPreviousMessage.chatId === ''+chatMessage.chatId)) {
                        step4_messages[chatMessage._id] = chatMessage;
                    }
                });
            });
        }

        return function cleanup() {
            socket.off('chat.message');
            socket.off('chat.message.edit');
            socket.off('chat.message.delete');
        }
    }, [step4_chatId, step4_updateMessages]);

    useEffect(() => {
        if(step4_chatId) {
            socket.off('chat.connectToOperator').on('chat.connectToOperator', (pData) => {
                if(step4_chatId && ''+step4_chatId !== ''+pData.chatId) return;
                socket.emit('visitor.chat.connectToOperator');
            });
        }

        return function cleanup() {
            socket.off('chat.connectToOperator');
        }
    }, [step4_chatId]);

    useEffect(() => {
        if(step4_chatId) {
            socket.off('chat.end').on('chat.end', (pData) => {
                if(step4_chatId && ''+step4_chatId !== ''+pData.chatId) return;

                if(previewChatbotId) {
                    killWidget();
                    return;
                }

                step4_setChatId(null);
                step4_setOperator(null);
                step4_updateMessages(step4_messages => null);

                setStep(5);
            });
        }

        return function cleanup() {
            socket.off('chat.end');
        }
    }, [step4_chatId, previewChatbotId, killWidget, step4_updateMessages]);

    useEffect(() => {
        if(!step4_chatId) {
            socket.emit('visitor.chat.get', null, (ack) => {
                if(ack && ack.result && ack.chat) {
                    step4_setChatId(ack.chat._id);
                }
            });
        } else {
            socket.emit('visitor.chat.join', step4_chatId, (ack) => {
                if(ack && ack.result && ack.chat) {
                    if(ack.chat.chatbotId) {
                        setStep(4);
                    } else if(!ack.chat.operatorId) {
                        step3_updateQueuePosition(step3_queuePosition => (ack.chat.queuePosition && parseInt(ack.chat.queuePosition) > 0 ? parseInt(ack.chat.queuePosition) : 0));
                        setStep(3);
                    } else {
                        if(ack.chat.operator) step4_setOperator(ack.chat.operator);
                        setStep(4);
                    }

                    expandWidget();
                }
            });

            socket.emit('visitor.chat.history', null, (ack) => {
                if(ack && ack.result && ack.response) {
                    if(ack.response.pagination) {
                        // nothing-to-do
                    }
        
                    if(ack.response.history) {
                        step4_updateMessages(step4_messages => (step4_messages ? { ...ack.response.history, ...step4_messages } : { ...ack.response.history }));
                    }
                }
            });
        }
    }, [step4_chatId, expandWidget, step3_updateQueuePosition, step4_updateMessages]);

    useEffect(() => {
        if(step4_chatId && step4_messages) {
            setTimeout(step4_scrollChatLogToBottom, 100);

            let messagesArr = Object.values(step4_messages);
            let chatMessage = messagesArr.pop();
            while(chatMessage) {
                if(chatMessage.parameters && chatMessage.parameters.chatbotId) {
                    chatMessage.parameters.chatbotNodeId && step4_setChatbotNodeId(chatMessage.parameters.chatbotNodeId);
                    break;
                } else if(chatMessage.messageBy && chatMessage.messageBy.userType && chatMessage.messageBy.userType === 'operator') {
                    step4_setChatbotNodeId(null);
                    break;
                }

                chatMessage = messagesArr.pop();
            }
        }
    }, [step4_chatId, step4_messages]);

    const step2_onFormSubmit = (values) => {
        // TODO: emit visitor update (or update automatically on visitor change using useEffect - think about this!)
        updateVisitor(visitor => {
            let nVisitor = {
                name: values.name,
                username: values.username,
                email: values.email
            };

            if(visitor) nVisitor = { ...visitor, ...nVisitor };
            if(!_.isEqual(visitor, nVisitor)) return nVisitor;
        });

        if(step1_channel && step1_channel === '$TICKET') {
            socket.emit('visitor.ticket.new', values, (ack) => {
                if(ack && ack.result) setStep(5);
            });
            return;
        }

        setStep(3);

        socket.emit('visitor.chat.start', values, (ack) => {
            if(ack && ack.result === false && ack.ticket) {
                step5_setWarningMessage(t('Widget__Step5__weAreExperiencingAHighVolumeOfConnections'));
                setStep(4);
                return;
            }
        });

        return;
    }

    const step3_toggleIsEndChatConfirmationVisible = () => {
        step3_setIsEndChatConfirmationVisible(!step3_isEndChatConfirmationVisible);
    }

    const step3_endChat = () => {
        socket.emit('visitor.chat.end', null, (ack) => {
            if(ack && ack.result) step3_setIsEndChatConfirmationVisible(false);
        });
    }

    const step4_scrollChatLogToBottom = () => {
        step4_ref_bottomOfChatLog && step4_ref_bottomOfChatLog.current && step4_ref_bottomOfChatLog.current.scrollIntoView({ behavior: "smooth" });
    };

    const step4_toggleIsEndChatConfirmationVisible = () => {
        step4_setIsEndChatConfirmationVisible(!step4_isEndChatConfirmationVisible);
    }

    const step4_endChat = () => {
        socket.emit('visitor.chat.end', null, (ack) => {
            if(ack && ack.result) step4_setIsEndChatConfirmationVisible(false);
        });
    }

    let step4_message_onChange_timer = null;

    const step4_message_onChange = (e) => {
        // temporarily disabled

        // if(step4_message_onChange_timer) clearTimeout(step4_message_onChange_timer);

        // step4_message_onChange_timer = setTimeout(function(pMessage) {
        //     socket.emit('visitor.chat.onKeyDown', pMessage);
        // }.bind(this, e.target.value), 500);
    }

    const step4_message_onPressEnter = (e) => {
        e.preventDefault();
        if(step4_message_onChange_timer) clearTimeout(step4_message_onChange_timer);
        step4_messageComposerForm.submit();
    }

    /*
    const step4_message_onKeyDown = (e) => {
        if(e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            if(step4_message_onChange_timer) clearTimeout(step4_message_onChange_timer);
            step4_message_onPressEnter();
        }
    }
    */

    const step4_addEmoji = (event, selected) => {
        event.preventDefault();

        step4_messageComposerForm.setFieldsValue({
            message: (step4_messageComposerForm.getFieldValue('message') ? step4_messageComposerForm.getFieldValue('message') : '') + selected.emoji
        });
    }

    const step4_file_beforeUpload = (file, fileList) => {
        step4_file_setUploadProgress(0);
        return true;
    }

    const step4_file_onUploadSuccess = (response) => {
        let chatMessage = response.chatMessage || null;
        if(!chatMessage) return;

        socket.emit('visitor.chat.message', { ...chatMessage, chatbotNodeId: step4_chatbotNodeId }, (ack) => {
            if(ack && ack.result && ack.tempChatMessageId) {
                step4_updateMessages(step4_messages => {
                    if(step4_messages && step4_messages[ack.tempChatMessageId]) {
                        step4_messages[ack.tempChatMessageId].isSent = true;
                    }
                });
            }
        });
    }

    const step4_file_onUploadError = (err = null) => {
        let chatMessage = {
            _id: uuid(),
            chatId: step4_chatId,
            messageType: (systemVariables && systemVariables.chat && systemVariables.chat.messageTypes && Object.keys(systemVariables.chat.messageTypes).find(key => systemVariables.chat.messageTypes[key] === 'Error')),
            messageBy: { userType: 'visitor', userId: visitor.userId, name: visitor.name },
            message: (err && err.message ? err.message : 'An unknown error occured while uploading the file. Please try again.'),
            createdAt: moment().toISOString(),
            updatedAt: moment().toISOString(),
        };

        step4_updateMessages(step4_messages => {
            if(!step4_messages) return { [chatMessage._id]: chatMessage };
            step4_messages[chatMessage._id] = chatMessage;
        });

        socket.emit('operator.chat.message', { chatId: chatMessage.chatId, messageType: chatMessage.messageType, message: chatMessage.message, tempChatMessageId: chatMessage._id }, (ack) => {
            if(ack && ack.result && ack.tempChatMessageId) {
                step4_updateMessages(step4_messages => {
                    if(step4_messages && step4_messages[ack.tempChatMessageId]) {
                        step4_messages[ack.tempChatMessageId].isSent = true;
                    }
                });
            }
        });
    }

    const step4_chatbot_file_download = async (chatbotId, fileName, type, originalFileName) => {
        axios(process.env.REACT_APP_CHATLINE_API_ADDRESS + 'chatbots/' + chatbotId + '/nodes/files/view-image/' + fileName, {
            headers: { clientId: client._id },
            method: 'GET',
            responseType: 'blob',
        }).then(response => {
            const file = new Blob([response.data], { type: type });
            const fileURL = URL.createObjectURL(file);

            const link = document.createElement('a');
            link.href = fileURL;
            link.setAttribute('download', originalFileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch(err => {
            console.error(err);
        });
    }

    const step4_chat_file_download = async (chatId, fileName, type, originalFileName) => {
        axios(process.env.REACT_APP_CHATLINE_API_ADDRESS + 'chats/' + chatId + '/attachments/' + fileName, {
            headers: { clientId: client._id },
            method: 'GET',
            responseType: 'blob',
        }).then(response => {
            const file = new Blob([response.data], { type: type });
            const fileURL = URL.createObjectURL(file);

            const link = document.createElement('a');
            link.href = fileURL;
            link.setAttribute('download', originalFileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }).catch(err => {
            console.error(err);
        });
    }

    const step4_formatAvatar = (userType, userName) => {
        let avatarSize = 35;

        if(userType === 'chatbot') {
            return (
                <Avatar size={ avatarSize } className="avatar">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-robot" viewBox="0 0 16 16">
                        <path d="M6 12.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5ZM3 8.062C3 6.76 4.235 5.765 5.53 5.886a26.58 26.58 0 0 0 4.94 0C11.765 5.765 13 6.76 13 8.062v1.157a.933.933 0 0 1-.765.935c-.845.147-2.34.346-4.235.346-1.895 0-3.39-.2-4.235-.346A.933.933 0 0 1 3 9.219V8.062Zm4.542-.827a.25.25 0 0 0-.217.068l-.92.9a24.767 24.767 0 0 1-1.871-.183.25.25 0 0 0-.068.495c.55.076 1.232.149 2.02.193a.25.25 0 0 0 .189-.071l.754-.736.847 1.71a.25.25 0 0 0 .404.062l.932-.97a25.286 25.286 0 0 0 1.922-.188.25.25 0 0 0-.068-.495c-.538.074-1.207.145-1.98.189a.25.25 0 0 0-.166.076l-.754.785-.842-1.7a.25.25 0 0 0-.182-.135Z" />
                        <path d="M8.5 1.866a1 1 0 1 0-1 0V3h-2A4.5 4.5 0 0 0 1 7.5V8a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1v1a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-1a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1v-.5A4.5 4.5 0 0 0 10.5 3h-2V1.866ZM14 7.5V13a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7.5A3.5 3.5 0 0 1 5.5 4h5A3.5 3.5 0 0 1 14 7.5Z" />
                    </svg>
                </Avatar>
            );  
        }

        if(!userName) userName = '?';

        return (
            <Avatar size={ avatarSize } className="avatar">{ userName.substring(0, 1).toUpperCase() }</Avatar>
        );
    }

    const step4_formatChatMessage = (message, i, cDate) => {
        if(message.status !== null && systemVariables && systemVariables.chatMessage && systemVariables.chatMessage.statusOptions && systemVariables.chatMessage.statusOptions[message.status] && systemVariables.chatMessage.statusOptions[message.status] === 'Deleted') return;

        if(message.visibleToOperatorsOnly) return;

        if(message.visibleToSenderOnly && message.messageBy.userType !== 'visitor') return;

        if(systemVariables && systemVariables.chat && systemVariables.chat.messageTypes && systemVariables.chat.messageTypes[message.messageType] && systemVariables.chat.messageTypes[message.messageType] === 'Info') {
            return (
                <div key={'chat-log-message-wrapper-' + i} className="chat-log-message-wrapper chat-log-message-wrapper--center pt-3">
                    { step4_formatAvatar(message.messageBy.userType, message.messageBy.name) }
                    <div className="message-text mt-2">{ nl2br(message.message) }</div>
                    <div className="message-details">{ moment(message.createdAt).format('HH:mm:ss') }</div>
                </div>
            );
        }

        let isMessageSentByCurrentUser = (message.messageBy && message.messageBy.userType && message.messageBy.userType === 'visitor' ? true : false);

        return (
            <div key={ 'chat-log-message-wrapper' + i } className={ 'chat-log-message-wrapper ' + (isMessageSentByCurrentUser ? 'chat-log-message-wrapper--right' : 'chat-log-message-wrapper--left') }>
                <div className="chat-log-message-container">
                    { !isMessageSentByCurrentUser && <div className="message-by--avatar">{ step4_formatAvatar(message.messageBy.userType, message.messageBy.name) }</div> }

                    {
                        (systemVariables && systemVariables.chat && systemVariables.chat.messageTypes && systemVariables.chat.messageTypes[message.messageType] && systemVariables.chat.messageTypes[message.messageType] === 'File') ? (
                            <div className="message-file">
                                {
                                    ['image/jpeg', 'image/png', 'image/gif'].includes(message.attachment.mime) ? (
                                        <>
                                            <img className="message-file--image" src={ process.env.REACT_APP_CHATLINE_API_ADDRESS + message.attachment.filePath.replace(/^public\//, '') } alt={ message.attachment.originalFileName } onClick={ () => {
                                                if(!message.parameters || !message.parameters.linkTo) return false;

                                                if(message.parameters.target) window.open(message.parameters.linkTo, message.parameters.target);
                                                else window.open(message.parameters.linkTo, '_blank');
                                                return true;
                                            }} style={ ((message.parameters && message.parameters.linkTo) ? { cursor: 'pointer' } : {}) } />

                                            <div className="message-file---actions">
                                                <Button onClick={ () => { ((message.parameters && message.parameters.chatbotId) ? step4_chatbot_file_download(message.parameters.chatbotId, message.attachment.fileName, null, message.attachment.originalFileName) : step4_chat_file_download(message.chatId, message.attachment.fileName, null, message.attachment.originalFileName)) }} className="action"><DownloadOutlined /></Button>
                                            </div>
                                        </>
                                    ) : (
                                        <div onClick={ () => { ((message.parameters && message.parameters.chatbotId) ? step4_chatbot_file_download(message.parameters.chatbotId, message.attachment.fileName, null, message.attachment.originalFileName) : step4_chat_file_download(message.chatId, message.attachment.fileName, null, message.attachment.originalFileName)) }} className="file"><FileOutlined /> { message.attachment.originalFileName } <DownloadOutlined /></div>
                                    )
                                }
                            </div>
                        ) : (systemVariables && systemVariables.chat && systemVariables.chat.messageTypes && systemVariables.chat.messageTypes[message.messageType] && systemVariables.chat.messageTypes[message.messageType] === 'Button Group') ? (
                            <div className="message-button-group">
                                {
                                    (message.parameters && message.parameters.buttons && Array.isArray(message.parameters.buttons) && message.parameters.buttons.map((button, buttonIndex) => {
                                        if(!button.label) return null;

                                        return (    
                                            <Button
                                                key={ 'btn-action-' + buttonIndex }
                                                className={ 'btn-action type-'+(button.type || (systemVariables && systemVariables.chatbotNode && systemVariables.chatbotNode.buttonTypeOptions && Object.keys(systemVariables.chatbotNode.buttonTypeOptions).find(key => systemVariables.chatbotNode.buttonTypeOptions[key] === 'User response'))) }
                                                onClick={
                                                    () => {
                                                        if(button.type === (systemVariables && systemVariables.chatbotNode && systemVariables.chatbotNode.buttonTypeOptions && Object.keys(systemVariables.chatbotNode.buttonTypeOptions).find(key => systemVariables.chatbotNode.buttonTypeOptions[key] === 'Link'))) {
                                                            window.open(button.url, '_blank');
                                                            return true;
                                                        } else if(button.type === (systemVariables && systemVariables.chatbotNode && systemVariables.chatbotNode.buttonTypeOptions && Object.keys(systemVariables.chatbotNode.buttonTypeOptions).find(key => systemVariables.chatbotNode.buttonTypeOptions[key] === 'Connect to operator'))) {
                                                            socket.emit('visitor.chat.connectToOperator');
                                                        } else if(button.type === (systemVariables && systemVariables.chatbotNode && systemVariables.chatbotNode.buttonTypeOptions && Object.keys(systemVariables.chatbotNode.buttonTypeOptions).find(key => systemVariables.chatbotNode.buttonTypeOptions[key] === 'End chat'))) {
                                                            step4_endChat();
                                                        } else {
                                                            socket.emit('visitor.chat.message', { message: button.label, chatbotNodeId: step4_chatbotNodeId });
                                                        }
                                                    }
                                                }
                                            >
                                                { button.label }
                                            </Button>
                                        );
                                    }))
                                }
                            </div>
                        ) : (systemVariables && systemVariables.chat && systemVariables.chat.messageTypes && systemVariables.chat.messageTypes[message.messageType] && systemVariables.chat.messageTypes[message.messageType] === 'Carousel') ? (
                            <div className="message-carousel">
                                {
                                    (message.parameters && message.parameters.fields && message.parameters.carouselItems && Array.isArray(message.parameters.carouselItems) && (
                                        <Carousel effect="scrollx" dotPosition="top" arrows={ true } prevArrow={ <ArrowLeftOutlined /> } nextArrow={ <ArrowRightOutlined /> }>
                                            {
                                                (message.parameters.carouselItems.map((carouselItem, carouselItemIndex) => {
                                                    if(!carouselItem.image) return null;

                                                    return (
                                                        <div key={ 'carousel-item-' + carouselItemIndex } className="carousel-item">
                                                            <img className="carousel-item--image" src={ process.env.REACT_APP_CHATLINE_API_ADDRESS + carouselItem.image.filePath.replace(/^public\//, '') } alt={ carouselItem.title || carouselItem.image.fileName || null } />

                                                            { (carouselItem.title) && <div className="carousel-item--title">{ carouselItem.title }</div> }
                                                            { (carouselItem.description) && <div className="carousel-item--description">{ carouselItem.description }</div> }

                                                            {
                                                                (carouselItem.buttons && Array.isArray(carouselItem.buttons) && carouselItem.buttons.length > 0) && (
                                                                    <div className="carousel-item--button-group">
                                                                        {
                                                                            (carouselItem.buttons.map((button, buttonIndex) => {
                                                                                if(!button.label) return null;

                                                                                return (    
                                                                                    <Button
                                                                                        key={ 'carousel-item--button-group--button-' + buttonIndex }
                                                                                        className={ 'btn-action type-'+(button.type || (systemVariables && systemVariables.chatbotNode && systemVariables.chatbotNode.buttonTypeOptions && Object.keys(systemVariables.chatbotNode.buttonTypeOptions).find(key => systemVariables.chatbotNode.buttonTypeOptions[key] === 'User response'))) }
                                                                                        onClick={
                                                                                            () => {
                                                                                                if(button.type === (systemVariables && systemVariables.chatbotNode && systemVariables.chatbotNode.buttonTypeOptions && Object.keys(systemVariables.chatbotNode.buttonTypeOptions).find(key => systemVariables.chatbotNode.buttonTypeOptions[key] === 'Link'))) {
                                                                                                    window.open(button.url, '_blank');
                                                                                                    return true;
                                                                                                } else if(button.type === (systemVariables && systemVariables.chatbotNode && systemVariables.chatbotNode.buttonTypeOptions && Object.keys(systemVariables.chatbotNode.buttonTypeOptions).find(key => systemVariables.chatbotNode.buttonTypeOptions[key] === 'Connect to operator'))) {
                                                                                                    socket.emit('visitor.chat.connectToOperator');
                                                                                                } else if(button.type === (systemVariables && systemVariables.chatbotNode && systemVariables.chatbotNode.buttonTypeOptions && Object.keys(systemVariables.chatbotNode.buttonTypeOptions).find(key => systemVariables.chatbotNode.buttonTypeOptions[key] === 'End chat'))) {
                                                                                                    step4_endChat();
                                                                                                } else {
                                                                                                    socket.emit('visitor.chat.message', { message: button.label, chatbotNodeId: step4_chatbotNodeId });
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    >
                                                                                        { button.label }
                                                                                    </Button>
                                                                                );
                                                                            }))
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    );
                                                }))
                                            }
                                        </Carousel>
                                    ))
                                }
                            </div>
                        ) : (
                            <div className="message-text">
                                <Linkify componentDecorator={ (href, text, key) => (<a href={ href}  key={ key } target="_blank" rel="noopener noreferrer">{ text }</a>) }>{ nl2br(message.message) }</Linkify>
                            </div>
                        )
                    }

                    { !isMessageSentByCurrentUser && <div className="message-details">{ (message.messageBy && message.messageBy.name) || 'Bot' } - { moment(message.createdAt).format('HH:mm:ss') }</div> }
                </div>
            </div>
        );
    }

    const step4_sendMessage = (values) => {
        let message = (values && values.message && values.message.trim());
        if(!message) return;

        step4_messageComposerForm.setFieldsValue({ message: '' });

        socket.emit('visitor.chat.message', { message: message, chatbotNodeId: step4_chatbotNodeId }, (ack) => {
            if(ack && ack.result) {
                // socket.emit('visitor.chat.onKeyDown', ''); // temporarily disabled
            }
        });
    }

    const step5_startAgain = () => {
        step3_updateQueuePosition(0);
        step4_setChatId(null);
        step4_setOperator(null);
        step4_updateMessages(messages => null);
        step4_file_setUploadProgress(0);
        step5_setWarningMessage(null);

        setStep(1);
    }

    return (
        <>
            {
                (client && client.parameters && client.parameters.widget && client.parameters.widget.styling) && (
                    <style type="text/css">{ client.parameters.widget.styling }</style>
                )
            }

            <div className="widget-wrapper">
                {
                    (step === 1) ? (
                        <div key="widget-step1" className="widget-container">
                            <div className="widget-header">
                                <div className="widget-header-col" style={{ width: '67px' }}></div>
                                <div className="widget-header-col" style={{ width: 'calc(100% - 134px)', textAlign: 'center' }}>
                                    { ((client.parameters && client.parameters.widget && client.parameters.widget.preChat1_widgetTitle) || client.businessName + ' ' + t('Widget__COMMON__liveSupport')) }
                                </div>
                                <div className="widget-header-col">
                                    <div className="button-wrapper" tabIndex="0" aria-label="Close" role="button">
                                        <div className="button-container" onClick={ toggleWidget }>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash-lg" viewBox="0 0 16 16"><path fillRule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"/></svg>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="widget-body">
                                <div className="widget-body-container">
                                    <div id="widget-step1" className="widget-body-container-padder">
                                        { (client.parameters && client.parameters.widget && client.parameters.widget.preChat1_greeting && <div className="prechat-greeting-container" dangerouslySetInnerHTML={{ __html: client.parameters.widget.preChat1_greeting.replace(/style="([^"]*)(font-size:[^"]+;)([^"]*)"/g, 'style="$1$3"').replace(/style="([^"]*)(font-family:[^"]+;)([^"]*)"/g, 'style="$1$3"') }} />) }

                                        <div className="channels">
                                            <div className="channel" onClick={() => { step1_setChannel('$CHAT'); setStep(2); }}>
                                                <div className="channel-name"> <MessageOutlined /> {t('Widget__Step1__LiveChatChannel_title')} </div>
                                                <div className="channel-helper-text"> {t('Widget__Step1__LiveChatChannel_helperText')} </div>
                                                <Button className="channel-button mt-3">{t('Widget__Step1__LiveChatChannel_button')}</Button>
                                            </div>

                                            {
                                                (client.parameters && client.parameters.widget && client.parameters.widget.preChat1_isTicketOptionEnabled !== null && parseInt(client.parameters.widget.preChat1_isTicketOptionEnabled) === 1 && (
                                                    <div className="channel" onClick={() => { step1_setChannel('$TICKET'); setStep(2); }}>
                                                        <div className="channel-name"> <SendOutlined /> {t('Widget__Step1__TicketChannel_title')} </div>
                                                        <div className="channel-helper-text"> {t('Widget__Step1__TicketChannel_helperText')} </div>
                                                        <Button className="channel-button mt-3">{t('Widget__Step1__TicketChannel_button')}</Button>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (step === 2) ? (
                        <div key="widget-step2" className="widget-container">
                            <div className="widget-header">
                                <div className="widget-header-col">
                                    <div className="button-wrapper" tabIndex="0" aria-label="Back" role="button">
                                        <div className="button-container" onClick={ () => { setStep(step-1); } }>
                                            <svg focusable="false" style={{ width: '9px', height: '15px' }}><g fill="none"><g fill="#FFF"><polygon transform="translate(-40 -29)translate(47.071068 36.071068)rotate(-315)translate(-47.071068 -36.071068)" points="44.3 38.8 44.3 31.1 42.1 31.1 42.1 40 42.1 41.1 52.1 41.1 52.1 38.8"></polygon></g></g></svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="widget-header-col" style={{ width: 'calc(100% - 134px)', textAlign: 'center' }}>
                                    { ((client.parameters && client.parameters.widget && client.parameters.widget.preChat2_widgetTitle) || client.businessName + ' ' + t('Widget__COMMON__liveSupport')) }
                                </div>
                                <div className="widget-header-col">
                                    <div className="button-wrapper" tabIndex="0" aria-label="Close" role="button">
                                        <div className="button-container" onClick={ toggleWidget }>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash-lg" viewBox="0 0 16 16"><path fillRule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"/></svg>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="widget-body">
                                <div className="widget-body-container">
                                    <div id="widget-step2" className="widget-body-container-padder">
                                        { (client.parameters && client.parameters.widget && client.parameters.widget.preChat2_greeting && <div className="prechat-greeting-container" dangerouslySetInnerHTML={{ __html: client.parameters.widget.preChat2_greeting.replace(/style="([^"]*)(font-size:[^"]+;)([^"]*)"/g, 'style="$1$3"').replace(/style="([^"]*)(font-family:[^"]+;)([^"]*)"/g, 'style="$1$3"') }} />) }

                                        <Form layout="vertical" onFinish={ step2_onFormSubmit } hideRequiredMark={ true }>
                                            <Form.Item
                                                name="name"
                                                label={t('Widget__Step2__label_name')}
                                                initialValue={ (visitor && visitor.name) || null }
                                                rules={[{ required: true }]}
                                            >
                                                <Input disabled={(visitor && visitor.name ? true : false)} />
                                            </Form.Item>

                                            <Form.Item
                                                name="username"
                                                label={t('Widget__Step2__label_username')}
                                                initialValue={ (visitor && visitor.username) || null }
                                                rules={[{ required: true }]}
                                            >
                                                <Input disabled={ (visitor && visitor.username ? true : false)} />
                                            </Form.Item>

                                            {
                                                (client.parameters && client.parameters.widget && client.parameters.widget.preChat2_isEmailFieldVisible !== null && parseInt(client.parameters.widget.preChat2_isEmailFieldVisible) === 1) && (
                                                    <Form.Item
                                                        name="email"
                                                        label={t('Widget__Step2__label_email')}
                                                        initialValue={ (visitor && visitor.email) || null }
                                                        rules={[{ required: true, type: 'email' }]}
                                                    >
                                                        <Input type="email" disabled={(visitor && visitor.email ? true : false)} />
                                                    </Form.Item>
                                                )
                                            }

                                            <Form.Item
                                                name="departmentId"
                                                label={t('Widget__Step2__label_department')}
                                                rules={[{ required: true }]}
                                            >
                                                <Select>
                                                    {step2_departments && Object.values(step2_departments).length > 0 && Object.values(step2_departments).map(department => department.isVisibleToClient && <Select.Option key={department._id} value={department._id}>{department.name}</Select.Option>)}
                                                </Select>
                                            </Form.Item>

                                            {
                                                (step1_channel === '$TICKET') && (
                                                    <Form.Item
                                                        name="message"
                                                        label={ (client.parameters && client.parameters.widget && client.parameters.widget.preChat2_messageFieldLabel) || t('Widget__Step2__label_message') }
                                                        rules={[{ required: true, type: 'string', max: 256 }]}
                                                    >
                                                        <Input.TextArea row={3} maxLength={256}></Input.TextArea>
                                                    </Form.Item>
                                                )
                                            }

                                            <Form.Item>
                                                <Button className="btn-custom" type="primary" htmlType="submit">{t('Widget__Step2__startChat')}</Button>
                                            </Form.Item>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (step === 3) ? (
                        <div key="widget-step3" className="widget-container">
                            <div className="widget-header">
                                <div className="widget-header-col" style={{ width: '67px' }}></div>
                                <div className="widget-header-col" style={{ width: 'calc(100% - 134px)', textAlign: 'center' }}>
                                    { (client.parameters && client.parameters.widget && client.parameters.widget.preChat1_widgetTitle) || client.businessName + ' ' + t('Widget__COMMON__liveSupport') }
                                </div>
                                <div className="widget-header-col">
                                    <div className="button-wrapper" tabIndex="0" aria-label="Close" role="button">
                                        <div className="button-container" onClick={ step3_toggleIsEndChatConfirmationVisible }>
                                            <CloseOutlined />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="widget-body">
                                <div className="widget-body-container">
                                    <div id="widget-step3" className="widget-body-container-padder">
                                        {
                                            (step3_isEndChatConfirmationVisible) ? (
                                                <div className="box">
                                                    <div className="helper-text">{ t('Widget__Step3__endChatConfirmationMessage') }</div>
                                                    <div className="mt-4">
                                                        <Button onClick={ step3_endChat } className="btn-primary px-4">{ t('Widget__Step3__endChat_confirm') }</Button>
                                                        <Button onClick={ step3_toggleIsEndChatConfirmationVisible } className="btn-secondary px-4 ml-2">{ t('Widget__Step3__endChat_cancel') }</Button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="box">
                                                    <LoadingOutlined />
                                                    <div className="mt-4 text-align--center">{t('Widget__Step3__pleaseWait')}...</div>
                                                    <div className="helper-text mt-4 text-align--center">{t('Widget__Step3__youAreNowBeingConnected')} <br /><br /> {t('Widget__Step3__pleaseContinueToHold')} <br /><br /> { (step3_queuePosition && parseInt(step3_queuePosition) > 0 ? (t('Widget__Step3__queuePosition') + ' ' + step3_queuePosition) : '') } {  } </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (step === 4) ? (
                        <div key="widget-step4" className="widget-container">
                            <audio ref={ step4_ref_audio_newMessage }><source src={ asset_audio_newMessage } type="audio/mpeg" /></audio>

                            <div className="widget-header">
                                <div className="widget-header-col" style={{ width: '67px' }}>
                                    <div className="button-wrapper" tabIndex="0" aria-label="Minimize" role="button">
                                        <div className="button-container" onClick={ toggleWidget }>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash-lg" viewBox="0 0 16 16"><path fillRule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"/></svg>
                                        </div>
                                    </div>
                                </div>
                                <div className="widget-header-col" style={{ width: 'calc(100% - 134px)', textAlign: 'center' }}>
                                    { (client.parameters && client.parameters.widget && client.parameters.widget.preChat1_widgetTitle) || client.businessName + ' ' + t('Widget__COMMON__liveSupport') }
                                </div>
                                <div className="widget-header-col">
                                    <div className="button-wrapper" tabIndex="0" aria-label="Close" role="button">
                                        <div className="button-container" onClick={ step4_toggleIsEndChatConfirmationVisible }>
                                            <CloseOutlined />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="widget-body">
                                <div className="widget-body-container">
                                    <div id="widget-step4">
                                        {
                                            (step4_isEndChatConfirmationVisible) ? (
                                                <div className="box">
                                                    <div className="helper-text">{ t('Widget__Step4__endChatConfirmationMessage') }</div>
                                                    <div className="mt-4">
                                                        <Button onClick={ step4_endChat } className="btn-primary px-4">{ t('Widget__Step4__endChat_confirm') }</Button>
                                                        <Button onClick={ step4_toggleIsEndChatConfirmationVisible } className="btn-secondary px-4 ml-2">{ t('Widget__Step4__endChat_cancel') }</Button>
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    <div id="chat-log-container" className="chat-log-container">
                                                        { client && client.parameters && client.parameters.widget && client.parameters.widget.logo && client.parameters.widget.logo.path && <div style={{ textAlign: 'center' }}><img alt="Logo" className="logo py-2 px-3" style={{ maxWidth: '100%', maxHeight: '100px' }} src={ process.env.REACT_APP_CHATLINE_API_ADDRESS + '/' + client.parameters.widget.logo.path.replace(/^\/|\/$/g, '') } /></div> }

                                                        {
                                                            (step4_messages && Object.values(step4_messages).map((message, i) => {
                                                                let cDate = null;

                                                                if(i === 0) {
                                                                    cDate = moment(message.createdAt).format('LL');
                                                                } else if(i > 0) {
                                                                    let cPreviousMessage = Object.values(step4_messages)[i - 1];
                                                                    if(cPreviousMessage) {
                                                                        if(moment(cPreviousMessage.createdAt).startOf('day').format('LL') !== moment(message.createdAt).startOf('day').format('LL')) {
                                                                            cDate = moment(message.createdAt).format('LL');
                                                                        }
                                                                    }
                                                                }

                                                                return step4_formatChatMessage(message, i, cDate);
                                                            }))
                                                        }

                                                        {
                                                            (step4_file_uploadProgress !== 0) && (
                                                                <div key={'chat-log-message-wrapper--uploading'} className="chat-log-message-wrapper chat-log-message-wrapper--center">
                                                                    <div className="message-text">{t('Widget__Step4__uploadingFile')}...</div>

                                                                    <div className="px-2">
                                                                        <Progress percent={ parseInt(step4_file_uploadProgress) } status={ (step4_file_uploadProgress > 0 ? 'active' : 'exception') } />
                                                                    </div>
                                                                </div>
                                                            )
                                                        }

                                                        <div ref={ step4_ref_bottomOfChatLog } className="chat-log-container--bottom"></div>
                                                    </div>

                                                    <div className="chat-message-composer-container">
                                                        <Form form={ step4_messageComposerForm } onFinish={ step4_sendMessage } className="chat-message-composer--form" layout="vertical" hideRequiredMark={ true }>
                                                            <Form.Item name="message" className="chat-message-composer--form-item" rules={[{ required: true, type: 'string', max: 256 }]} hasFeedback={ false }>
                                                                <Input.TextArea
                                                                    className="chat-message-composer--textarea"
                                                                    maxLength={ 256 }
                                                                    rows={ 1 }
                                                                    autoSize = {{ minRows: 1, maxRows: 3 }}
                                                                    onPressEnter={ step4_message_onPressEnter }
                                                                    onChange={ step4_message_onChange }
                                                                />
                                                            </Form.Item>
                                                        </Form>

                                                        <div className="chat-message-composer--buttons">
                                                            <Button className="btn-icon" onClick={() => { step4_messageComposerForm_setEmojiPickerVisible(!step4_messageComposerForm_emojiPickerVisible) }}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-emoji-smile" viewBox="0 0 16 16">
                                                                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                                                                    <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z"/>
                                                                </svg>
                                                            </Button>

                                                            {
                                                                (client.parameters && client.parameters.widget && client.parameters.widget.fileSharingEnabled !== null && parseInt(client.parameters.widget.fileSharingEnabled) === 1) && (
                                                                    <Button className="btn-icon" onClick={() => { step4_ref_file_input && step4_ref_file_input.current && step4_ref_file_input.current.click(); }}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-paperclip" viewBox="0 0 16 16">
                                                                            <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z"/>
                                                                        </svg>
                                                                    </Button>
                                                                )
                                                            }

                                                            <Button className="btn-icon btn-icon--send" onClick={ () => { step4_messageComposerForm.submit(); } }>
                                                                <svg viewBox="64 64 896 896" focusable="false" data-icon="send" width="1em" height="1em" fill="currentColor" aria-hidden="true"><defs><style></style></defs><path d="M931.4 498.9L94.9 79.5c-3.4-1.7-7.3-2.1-11-1.2a15.99 15.99 0 00-11.7 19.3l86.2 352.2c1.3 5.3 5.2 9.6 10.4 11.3l147.7 50.7-147.6 50.7c-5.2 1.8-9.1 6-10.3 11.3L72.2 926.5c-.9 3.7-.5 7.6 1.2 10.9 3.9 7.9 13.5 11.1 21.5 7.2l836.5-417c3.1-1.5 5.6-4.1 7.2-7.1 3.9-8 .7-17.6-7.2-21.6zM170.8 826.3l50.3-205.6 295.2-101.3c2.3-.8 4.2-2.6 5-5 1.4-4.2-.8-8.7-5-10.2L221.1 403 171 198.2l628 314.9-628.2 313.2z"></path></svg>
                                                            </Button>
                                                        </div>

                                                        {
                                                            (step4_messageComposerForm_emojiPickerVisible) && (
                                                                <div className="chat-message-composer--emojiPicker-container">
                                                                    <Picker 
                                                                        native={ false }
                                                                        onEmojiClick={ step4_addEmoji }
                                                                        disableAutoFocus={ true }
                                                                        disableSearchBar={ true }
                                                                        skinTone={ SKIN_TONE_LIGHT }
                                                                        groupVisibility={{ recently_used: false }}
                                                                    />
                                                                </div>
                                                            )
                                                        }

                                                        <div className="chat-message-composer--fileUpload-container">
                                                            <Upload
                                                                name="file"
                                                                className="chat-message-composer--file"
                                                                action={ process.env.REACT_APP_CHATLINE_API_ADDRESS + 'chats/' + step4_chatId + '/upload' }
                                                                headers={{ clientId: (client && client._id) }}
                                                                showUploadList={ false }
                                                                beforeUpload={ step4_file_beforeUpload }
                                                                onChange={ 
                                                                    (info) => {
                                                                        if(info.file.status === 'uploading') {
                                                                            step4_file_setUploadProgress(info.file.percent || 0);
                                                                            setTimeout(step4_scrollChatLogToBottom, 100);
                                                                        } else if(info.file.status === 'done') {
                                                                            step4_file_setUploadProgress(0);

                                                                            step4_file_onUploadSuccess((info && info.file && info.file.response) || null);
                                                                        } else if (info.file.status === 'error') {
                                                                            step4_file_setUploadProgress(-1);

                                                                            step4_file_onUploadError((info && info.error) || null);
                                                                        }
                                                                    }
                                                                }
                                                            >
                                                                <Button ref={ step4_ref_file_input } />
                                                            </Upload>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (step === 5) ? (
                        <div key="widget-step5" className="widget-container">
                            <div className="widget-header">
                                <div className="widget-header-col" style={{ width: '67px' }}></div>
                                <div className="widget-header-col" style={{ width: 'calc(100% - 134px)', textAlign: 'center' }}>
                                    { ((client.parameters && client.parameters.widget && client.parameters.widget.preChat1_widgetTitle) || client.businessName + ' ' + t('Widget__COMMON__liveSupport')) }
                                </div>
                                <div className="widget-header-col">
                                    <div className="button-wrapper" tabIndex="0" aria-label="Close" role="button">
                                        <div className="button-container" onClick={ toggleWidget }>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-dash-lg" viewBox="0 0 16 16"><path fillRule="evenodd" d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8Z"/></svg>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="widget-body">
                                <div className="widget-body-container">
                                    <div id="widget-step5" className="widget-body-container-padder">
                                        <div className="box">
                                            <div style={{ fontSize: '36px' }}><LikeOutlined /></div>
                                            <div className="mt-4 text-align--center">{t('Widget__Step5__thankYouForContactingUs')}</div>
                                            { step5_warningMessage && <div className="helper-text mt-4 text-align--center">{ step5_warningMessage }</div> }
                                            <Button onClick={() => { step5_startAgain(0); }} className="btn-primary mt-4 px-4">{t('Widget__Step5__startAgain')}</Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <></>
                    )
                }
            </div>
        </>
    );
};

export default Widget;
